import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './IconEuro.module.css';

const IconEuro = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="10" height="14" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 6.5a1 1 0 1 1 0-2h.884A6.726 6.726 0 0 1 8.25 0H9a1 1 0 1 1 0 2h-.75a4.752 4.752 0 0 0-4.184 2.5H8a.999.999 0 1 1 0 2H3.506a6.51 6.51 0 0 0-.006.25v.5c0 .084.003.169.006.25H8a.999.999 0 1 1 0 2H4.066A4.752 4.752 0 0 0 8.25 12H9a.999.999 0 1 1 0 2h-.75a6.725 6.725 0 0 1-6.366-4.5H1a1 1 0 1 1 0-2h.505a6.67 6.67 0 0 1-.005-.25v-.5c0-.084.002-.166.005-.25H1Z" />
    </svg>
  );
};

const { string } = PropTypes;

IconEuro.defaultProps = {
  className: null,
  rootClassName: null,
};

IconEuro.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconEuro;
