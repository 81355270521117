import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconLogout.module.css';

const IconLogout = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-29.000000, -213.000000)" fill="#4A4A4A" fillRule="nonzero">
          <g transform="translate(26.000000, 210.000000)">
            <path d="M17,8 L15.59,9.41 L17.17,11 L9,11 L9,13 L17.17,13 L15.59,14.58 L17,16 L21,12 L17,8 Z M5,5 L12,5 L12,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L12,21 L12,19 L5,19 L5,5 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconLogout.defaultProps = {
  className: null,
  rootClassName: null,
};

IconLogout.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconLogout;
