import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bool, func, number, shape, string } from 'prop-types';
import { Form as FinalForm, FormSpy } from 'react-final-form';

import config from '../../../config';
import { FormattedMessage, useIntl, injectIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { numberAtLeast } from '../../../util/validators';
import { CART_ADD, updateCart, updateCartSetInitial } from '../../../ducks/cart.duck';
import { manageDisableScrolling } from '../../../ducks/UI.duck';

import {
  Form,
  FieldSelect,
  FieldTextInput,
  InlineTextButton,
  Modal,
  NamedLink,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from '../../../components';

import css from './ProductOrderForm.module.css';

const renderForm = formRenderProps => {
  const {
    // FormRenderProps from final-form
    handleSubmit,
    form: formApi,

    // Custom props passed to the form component
    intl,
    history,
    formId,
    currentStock,
    listingId,
    isOwnListing,
    onFetchTransactionLineItems,
    onContactUser,
    fetchLineItemsInProgress,
    values,
    onUpdateCart,
    onUpdateCartSetInitial,
    updateCartInProgress,
    updateCartError,
    updateCartSuccess,
    onManageDisableScrolling,
    author,
    isAffiliate,
    websiteAffiliate,
    stripeConnected,
  } = formRenderProps;

  const handleOnChange = formValues => {
    const { quantity: quantityRaw } = formValues.values;
    const quantity = Number.parseInt(quantityRaw, 10);
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser && quantity && !fetchLineItemsInProgress) {
      onFetchTransactionLineItems({
        orderData: { quantity },
        listingId,
        isOwnListing,
      });
    }
  };

  // In case quantity and deliveryMethod are missing focus on that select-input.
  // Otherwise continue with the default handleSubmit function.
  const handleFormSubmit = e => {
    const { quantity } = values || {};
    if (!quantity || quantity < 1) {
      e.preventDefault();
      // Blur event will show validator message
      formApi.blur('quantity');
      formApi.focus('quantity');
    } else {
      handleSubmit(e);
    }
  };

  const handleAddToCart = e => {
    e.preventDefault();
    const { quantity } = values || {};
    if (!quantity || quantity < 1) {
      // Blur event will show validator message
      formApi.blur('quantity');
      formApi.focus('quantity');
    } else {
      onUpdateCart({ listingId, authorId: author.id, quantity, action: CART_ADD });
    }
  };

  const handleFollowWebsiteLink = event => {
    event.preventDefault();
    if (typeof window !== 'undefined') {
      window.open(websiteAffiliate, '_blank', 'noreferrer');
    }
  };

  const handleGoBack = () => {
    onUpdateCartSetInitial();
    history.goBack();
  };

  const showContactUser = typeof onContactUser === 'function';

  const onClickContactUser = e => {
    e.preventDefault();
    onContactUser();
  };

  const contactSellerLink = (
    <InlineTextButton onClick={onClickContactUser}>
      <FormattedMessage id="ProductOrderForm.finePrintNoStockLinkText" />
    </InlineTextButton>
  );
  const quantityRequiredMsg = intl.formatMessage({ id: 'ProductOrderForm.quantityRequired' });

  const hasStock = currentStock && currentStock > 0;
  const quantities = hasStock ? [...Array(currentStock).keys()].map(i => i + 1) : [];
  const hasNoStockLeft = typeof currentStock != null && currentStock === 0;
  const hasOneItemLeft = typeof currentStock != null && currentStock === 1;

  const submitInProgress = fetchLineItemsInProgress;
  const submitDisabled = !hasStock;
  const linkDisabled = !websiteAffiliate;

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormSpy subscription={{ values: true }} onChange={handleOnChange} />
      {hasNoStockLeft || isAffiliate || !stripeConnected ? null : hasOneItemLeft ? (
        <FieldTextInput
          id={`${formId}.quantity`}
          className={css.quantityField}
          name="quantity"
          type="hidden"
          validate={numberAtLeast(quantityRequiredMsg, 1)}
        />
      ) : (
        <FieldSelect
          id={`${formId}.quantity`}
          className={css.quantityField}
          name="quantity"
          disabled={!hasStock}
          label={intl.formatMessage({ id: 'ProductOrderForm.quantityLabel' })}
          validate={numberAtLeast(quantityRequiredMsg, 1)}
        >
          <option disabled value="">
            {intl.formatMessage({ id: 'ProductOrderForm.selectQuantityOption' })}
          </option>
          {quantities.map(quantity => (
            <option key={quantity} value={quantity}>
              {intl.formatMessage({ id: 'ProductOrderForm.quantityOption' }, { quantity })}
            </option>
          ))}
        </FieldSelect>
      )}

      {isAffiliate ? (
        <div className={css.submitButton}>
          <TertiaryButton
            className={css.addToCartButton}
            disabled={linkDisabled}
            onClick={handleFollowWebsiteLink}
            altIcon={true}
          >
            <FormattedMessage id="ProductOrderForm.followWebsiteLink" />
          </TertiaryButton>
          <SecondaryButton rootClassName={css.contactButton} onClick={onClickContactUser}>
            <FormattedMessage id="ProductOrderForm.contactButton" />
          </SecondaryButton>
        </div>
      ) : (
        <div className={css.submitButton}>
          {stripeConnected ? (
            <>
              <PrimaryButton
                className={css.buyNowButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
              >
                {hasStock ? (
                  <FormattedMessage id="ProductOrderForm.ctaButton" />
                ) : (
                  <FormattedMessage id="ProductOrderForm.ctaButtonNoStock" />
                )}
              </PrimaryButton>
              {hasStock && (
                <PrimaryButton
                  className={css.addToCartButton}
                  inProgress={!!updateCartInProgress}
                  disabled={submitDisabled}
                  onClick={handleAddToCart}
                >
                  <FormattedMessage id="ProductOrderForm.addToCartButton" />
                </PrimaryButton>
              )}
              {updateCartError && (
                <div>
                  <FormattedMessage id="ProductOrderForm.addToCartError" />
                </div>
              )}
              <SecondaryButton rootClassName={css.contactButton} onClick={onClickContactUser}>
                <FormattedMessage id="ProductOrderForm.contactButton" />
              </SecondaryButton>
            </>
          ) : (
            <>
              <p className={css.wellPrint}>
                <FormattedMessage id="ProductOrderForm.wellPrintOne" />
                <span className={css.wellPrintBold}>
                  <FormattedMessage id="ProductOrderForm.wellPrintTwo" />
                </span>
              </p>
              <SecondaryButton rootClassName={css.contactButton} onClick={onClickContactUser}>
                <FormattedMessage id="ProductOrderForm.contactButton" />
              </SecondaryButton>
            </>
          )}
        </div>
      )}
      <p className={css.finePrint}>
        {hasStock ? (
          <FormattedMessage id="ProductOrderForm.finePrint" />
        ) : showContactUser ? (
          <FormattedMessage id="ProductOrderForm.finePrintNoStock" values={{ contactSellerLink }} />
        ) : null}
      </p>
      <Modal
        id="AddedToCartModal"
        containerClassName=""
        contentClassName={css.modalContent}
        isOpen={!!updateCartSuccess}
        onClose={() => onUpdateCartSetInitial()}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <div className={css.cartUpdatedButtons}>
          <SecondaryButton className={css.cartUpdatedBack} onClick={handleGoBack}>
            <FormattedMessage id="ProductOrderForm.continueShopping" />
          </SecondaryButton>
          <NamedLink name="CartPage" className={css.goToCart}>
            <FormattedMessage id="ProductOrderForm.toCart" />
          </NamedLink>
        </div>
      </Modal>
    </Form>
  );
};

const ProductOrderForm = props => {
  const intl = useIntl();
  const { price, currentStock, pickupEnabled, shippingEnabled, history } = props;

  // Should not happen for listings that go through EditListingWizard.
  // However, this might happen for imported listings.
  if (!pickupEnabled && !shippingEnabled) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.noDeliveryMethodSet" />
      </p>
    );
  }

  if (!price) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingPriceMissing" />
      </p>
    );
  }
  if (price.currency !== config.currency) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingCurrencyInvalid" />
      </p>
    );
  }
  const hasOneItemLeft = currentStock && currentStock === 1;
  const quantityMaybe = { quantity: '1' };
  const singleDeliveryMethodAvailableMaybe =
    shippingEnabled && !pickupEnabled
      ? { deliveryMethod: 'shipping' }
      : !shippingEnabled && pickupEnabled
      ? { deliveryMethod: 'pickup' }
      : {};
  const hasMultipleDeliveryMethods = pickupEnabled && shippingEnabled;
  const initialValues = { ...quantityMaybe, ...singleDeliveryMethodAvailableMaybe };

  return (
    <FinalForm
      initialValues={initialValues}
      hasMultipleDeliveryMethods={hasMultipleDeliveryMethods}
      {...props}
      intl={intl}
      history={history}
      render={renderForm}
    />
  );
};

ProductOrderForm.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  currentStock: null,
  listingId: null,
  isOwnListing: false,
  lineItems: null,
  fetchLineItemsError: null,
};

ProductOrderForm.propTypes = {
  rootClassName: string,
  className: string,

  // form
  formId: string.isRequired,
  onSubmit: func.isRequired,

  // listing
  listingId: propTypes.uuid,
  price: propTypes.money,
  currentStock: number,
  isOwnListing: bool,

  // line items
  lineItems: propTypes.lineItems,
  onFetchTransactionLineItems: func.isRequired,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // other
  onContactUser: func,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { updateCartInProgress, updateCartError, updateCartSuccess } = state.cart;

  return {
    updateCartInProgress,
    updateCartError,
    updateCartSuccess,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateCart: data => dispatch(updateCart(data)),
  onUpdateCartSetInitial: () => dispatch(updateCartSetInitial()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const ProductOrderFormConnect = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  injectIntl
)(ProductOrderForm);
ProductOrderFormConnect.displayName = 'ProductOrderForm';

export default ProductOrderFormConnect;
