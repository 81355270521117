import React from 'react';
import NumberFormat from 'react-number-format';
import { bool, oneOfType, string } from 'prop-types';
import { currencyConfig } from '../../config';
import { number } from 'prop-types';

const DutchPrice = ({ price, showCurrency }) => {
  if (!price) {
    return;
  }

  const decimals = price == Math.floor(price) ? false : true;
  const priceSuffix = decimals ? null : ',-';

  const prefix = showCurrency ? '€' : null;

  return (
    <NumberFormat
      value={price}
      displayType="text"
      prefix={prefix}
      suffix={priceSuffix}
      decimalSeparator=","
      isNumericString={true}
    />
  );
};

DutchPrice.defaultProps = { price: null, showCurrency: false };

DutchPrice.propTypes = { price: oneOfType([string, number]), showCurrency: bool };

export default DutchPrice;
