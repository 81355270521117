import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoneyMinimal } from '../../util/currency';
import { LINE_ITEM_PICKUP_FEE, propTypes } from '../../util/types';
import { DutchPrice } from '../../components';

import css from './OrderBreakdown.module.css';

const LineItemPickupFeeMaybe = props => {
  const { lineItems, intl } = props;

  const pickupFeeLineItem = lineItems.find(
    item => item.code === LINE_ITEM_PICKUP_FEE && !item.reversal
  );

  const pickupFee = pickupFeeLineItem
    ? formatMoneyMinimal(intl, pickupFeeLineItem.lineTotal)
    : null;

  return pickupFeeLineItem ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.pickupFee" />
      </span>
      <span className={css.itemValue}>
        <DutchPrice price={pickupFee} showCurrency />
      </span>
    </div>
  ) : null;
};

LineItemPickupFeeMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemPickupFeeMaybe;
