import React, { useState } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';

import css from './AccordionItem.module.css';

const AccordionItem = props => {
  const { title, content, isOpen } = props;
  const [open, setOpen] = useState(isOpen);

  return (
    <div
      className={classNames(css.root, {
        [css.isOpen]: open,
      })}
    >
      <div className={css.header} onClick={() => setOpen(!open)}>
        <h3 className={css.title}>{title}</h3>
        <span className={css.arrow}></span>
      </div>
      {open && <div className={css.content} dangerouslySetInnerHTML={{ __html: content }}></div>}
    </div>
  );
};

AccordionItem.defaultProps = { isOpen: false };

AccordionItem.propTypes = {
  title: string.isRequired,
  content: string.isRequired,
  isOpen: bool,
};

export default AccordionItem;
