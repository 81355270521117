import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoneyMinimal } from '../../util/currency';
import { LINE_ITEM_MINIMUM_ORDER_FEE, propTypes } from '../../util/types';
import { DutchPrice } from '../../components';

import css from './OrderBreakdown.module.css';

const LineItemMinimumOrderFee = props => {
  const { lineItems, intl } = props;

  const lineItem = lineItems.find(
    item => item.code === LINE_ITEM_MINIMUM_ORDER_FEE && !item.reversal
  );

  const lineItemFormatted = lineItem ? formatMoneyMinimal(intl, lineItem.lineTotal) : null;

  return lineItem ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.minimumOrderFee" />
      </span>
      <span className={css.itemValue}>
        <DutchPrice price={lineItemFormatted} showCurrency />
      </span>
    </div>
  ) : null;
};

LineItemMinimumOrderFee.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemMinimumOrderFee;
