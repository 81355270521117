import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCart.module.css';

const IconCart = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="28"
      height="24"
      viewBox="0 0 28 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M5.4777,21.844 L22.2217,21.844 L25.3087,10.844 L2.4217,10.844 L5.4777,21.844 Z M5.2307,21.844 L22.4677,21.844 L5.2307,21.844 Z M9.5087,8.844 L18.0957,8.844 L13.8177,2.553 L9.5087,8.844 Z M4.9747,23.844 C4.6417,23.844 4.3447,23.745 4.0837,23.547 C3.8237,23.349 3.6517,23.094 3.5687,22.781 L0.0367,10.063 C-0.0463,9.75 0.0107,9.469 0.2087,9.219 C0.4067,8.969 0.6727,8.844 1.0057,8.844 L7.2557,8.844 L13.0367,0.407 C13.1417,0.282 13.2557,0.183 13.3807,0.11 C13.5057,0.037 13.6517,0 13.8187,0 C13.9847,0 14.1307,0.037 14.2557,0.11 C14.3807,0.183 14.4957,0.282 14.5997,0.407 L20.3497,8.844 L26.7557,8.844 C27.0687,8.844 27.3237,8.969 27.5217,9.219 C27.7197,9.469 27.7767,9.75 27.6937,10.063 L24.1307,22.781 C24.0477,23.094 23.8757,23.349 23.6147,23.547 C23.3547,23.745 23.0577,23.844 22.7247,23.844 L4.9747,23.844 Z"
          fill="#FFFFFF"
        ></path>
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconCart.defaultProps = {
  className: null,
  rootClassName: null,
};

IconCart.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCart;
