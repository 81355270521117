import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoneyMinimal } from '../../util/currency';
import { propTypes } from '../../util/types';
import config from '../../config';
import { DutchPrice } from '../../components';

import css from './OrderBreakdown.module.css';

const LineItemAdditionalItemsMaybe = props => {
  const { lineItems, intl, transaction } = props;

  const additionalItems = lineItems.filter(item => item.code.includes('additional-listing'));

  const unitOptions = config.custom.listingUnits;

  return additionalItems.length > 0 ? (
    <React.Fragment>
      {additionalItems.map((item, i) => {
        const quantity = item.quantity;
        const itemData =
          transaction.attributes.protectedData.cartAdditionalListings[item.code.substr(10, 40)];
        const humanizedCode = itemData?.title;
        const quantityMultiple = quantity && quantity > 1;
        const formattedTotal = item ? formatMoneyMinimal(intl, item.lineTotal) : null;

        const selectedUnit = unitOptions.find(u => u.key === itemData?.unit);
        const unitLabel = selectedUnit
          ? intl.formatMessage({
              id: quantityMultiple ? selectedUnit.labelPlural : selectedUnit.labelSingular,
            })
          : '';

        let quantityLabel = '';
        if (quantityMultiple) {
          if (selectedUnit?.key === 'per100Gram') {
            quantityLabel = `${quantity} x ${unitLabel}`;
          } else {
            quantityLabel = `${quantity} ${unitLabel}`;
          }
        } else {
          if (selectedUnit?.key === 'per100Gram') {
            quantityLabel = `1 x ${unitLabel}`;
          } else {
            quantityLabel = `1 ${unitLabel}`;
          }
        }

        return (
          <div key={`${i}-item.code`} className={css.lineItem}>
            <div className={css.itemImage}>
              <img src={itemData.image} />
            </div>
            <div className={css.itemInfo}>
              <p>
                <strong>{humanizedCode}</strong>
              </p>
              <p>{quantityLabel}</p>
            </div>
            <div className={css.itemValue}>
              <DutchPrice price={formattedTotal} showCurrency />
            </div>
          </div>
        );
      })}
    </React.Fragment>
  ) : null;
};

LineItemAdditionalItemsMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemAdditionalItemsMaybe;
