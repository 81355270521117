import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  /*
  {
    id: 'default-antwerp',
    predictionPlace: {
      address: 'Antwerp, België',
      bounds: new LatLngBounds(new LatLng(51.377641, 4.497881), new LatLng(51.143438, 4.2227767)),
    },
  },
  {
    id: 'default-ghent',
    predictionPlace: {
      address: 'Ghent, België',
      bounds: new LatLngBounds(new LatLng(51.188767, 3.849331), new LatLng(50.979496, 3.579786)),
    },
  },
  {
    id: 'default-charleroi',
    predictionPlace: {
      address: 'Charleroi, België',
      bounds: new LatLngBounds(new LatLng(50.492962, 4.507506), new LatLng(50.352892, 4.347774)),
    },
  },
  */
];
export default defaultLocations;
