import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconLightbulb.module.css';

const IconLightbulb = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="18" height="24" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter
          x="-.3%"
          y="-.3%"
          width="100.6%"
          height="100.6%"
          filterUnits="objectBoundingBox"
          id="a"
        >
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0884506119 0"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g filter="url(#a)" transform="translate(-228 -1088)" fill="#2F4F38" fillRule="nonzero">
        <path d="M234 1109c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-1h-6v1Zm3-19c-3.86 0-7 3.14-7 7 0 2.38 1.19 4.47 3 5.74v2.26c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2.26c1.81-1.27 3-3.36 3-5.74 0-3.86-3.14-7-7-7Zm2.85 11.1-.85.6v2.3h-4v-2.3l-.85-.6a4.997 4.997 0 0 1-2.15-4.1c0-2.76 2.24-5 5-5s5 2.24 5 5c0 1.63-.8 3.16-2.15 4.1Z" />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconLightbulb.defaultProps = {
  className: null,
  rootClassName: null,
};

IconLightbulb.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconLightbulb;
