import remove from 'lodash/remove';
import isNaN from 'lodash/isNaN';
import { denormalisedResponseEntities } from '../util/data';
import { storableError } from '../util/errors';
import * as log from '../util/log';
import { currentUserShowSuccess } from './user.duck';

// ================ Action types ================ //

export const UPDATE_CART_REQUEST = 'app/user/UPDATE_CART_REQUEST';
export const UPDATE_CART_SUCCESS = 'app/user/UPDATE_CART_SUCCESS';
export const UPDATE_CART_ERROR = 'app/user/UPDATE_CART_ERROR';
export const UPDATE_CART_SET_INITAL = 'app/user/UPDATE_CART_SET_INITAL';

// ================ Reducer ================ //

const initialState = {
  updateCartInProgress: null,
  updateCartError: null,
  updateCartSuccess: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_CART_REQUEST:
      return {
        ...state,
        updateCartError: null,
        updateCartInProgress: payload.uuid,
        updateCartSuccess: null,
      };
    case UPDATE_CART_SUCCESS:
      return {
        ...state,
        updateCartError: null,
        updateCartInProgress: null,
        updateCartSuccess: payload.uuid,
      };
    case UPDATE_CART_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        updateCartError: payload,
        updateCartInProgress: null,
        updateCartSuccess: null,
      };
    case UPDATE_CART_SET_INITAL:
      return {
        ...state,
        updateCartError: null,
        updateCartInProgress: null,
        updateCartSuccess: null,
      };

    default:
      return state;
  }
}

export const updateCartRequest = data => ({ type: UPDATE_CART_REQUEST, payload: data });
export const updateCartSuccess = data => ({ type: UPDATE_CART_SUCCESS, payload: data });
export const updateCartError = e => ({
  type: UPDATE_CART_ERROR,
  payload: e,
  error: true,
});
export const updateCartSetInitial = data => ({ type: UPDATE_CART_SET_INITAL });

export const CART_ADD = 'add';
export const CART_REMOVE = 'remove';
export const CART_SET = 'set';
export const CART_REMOVE_ITEM = 'remove-item';
export const CART_REMOVE_ALL_SELLER_ITEMS = 'remove-all-seller-items';

const getUpdatedCart = ({ currentUser, listingId, authorId, quantity, deliveryMethod, action }) => {
  quantity = parseInt(quantity);
  const currentCart = (currentUser && currentUser.attributes.profile.publicData.cart) || [];
  const cart = [...currentCart];
  const itemInCart = cart.find(i => i.id === listingId.uuid);
  const itemInCartIndex = cart.findIndex(i => i.id === listingId.uuid);
  if (action === CART_REMOVE_ALL_SELLER_ITEMS) {
    remove(cart, i => i.authorId === authorId.uuid);
    return cart;
  }
  if (itemInCart) {
    if ([CART_ADD, CART_REMOVE, CART_SET].indexOf(action) >= 0 && isNaN(parseInt(quantity))) {
      // ignore unexpexted quantity values
      return cart;
    }
    if (action === CART_ADD) {
      itemInCart.quantity += quantity;
    }
    if (action === CART_REMOVE) {
      const newQuantity = itemInCart.quantity - quantity;
      if (newQuantity <= 0) {
        cart.splice(itemInCartIndex, 1);
      } else {
        itemInCart.quantity = newQuantity;
      }
    }
    if (action === CART_SET) {
      if (quantity <= 0) {
        cart.splice(itemInCartIndex, 1);
      } else {
        itemInCart.quantity = quantity;
      }
    }
    if (action === CART_REMOVE_ITEM) {
      remove(cart, i => i.id === itemInCart.id);
    }
  } else if (action === CART_ADD) {
    cart.push({
      id: listingId.uuid,
      authorId: authorId.uuid,
      quantity,
      deliveryMethod,
    });
  }

  return cart;
};

export const updateCart = ({ listingId, authorId, quantity, deliveryMethod, action }) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(updateCartRequest(listingId));
  const { user } = getState();
  const { currentUser } = user;

  const queryParams = {
    expand: true,
  };

  const cart = getUpdatedCart({
    currentUser,
    listingId,
    authorId,
    quantity,
    deliveryMethod,
    action,
  });
  const updateParams = {
    publicData: {
      cart,
    },
  };

  return sdk.currentUser
    .updateProfile(updateParams, queryParams)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }
      const currentUser = entities[0];

      // Update current user in state.user.currentUser through user.duck.js
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(updateCartSuccess(listingId));
      if (action === CART_REMOVE_ALL_SELLER_ITEMS || action === CART_REMOVE_ITEM) {
        dispatch(updateCartSetInitial());
      }
    })
    .catch(e => dispatch(updateCartError(storableError(e))));
};
