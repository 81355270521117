import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldCheckbox.module.css';

const IconCheckbox = props => {
  const { className, checkedClassName, boxClassName } = props;
  return (
    <svg className={className} width="18" height="18" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(1,0,0,1,2,2)">
        <g transform="matrix(1,0,0,1,-2,-2)">
          <path
            className={boxClassName || css.box}
            d="M18,2.018C18,0.904 17.096,0 15.982,0L2.018,0C0.904,0 0,0.904 0,2.018L0,15.982C0,17.096 0.904,18 2.018,18L15.982,18C17.096,18 18,17.096 18,15.982L18,2.018Z"
          />
          <path
            className={checkedClassName || css.checked}
            d="M18,2.018C18,0.904 17.096,0 15.982,0L2.018,0C0.904,0 0,0.904 0,2.018L0,15.982C0,17.096 0.904,18 2.018,18L15.982,18C17.096,18 18,17.096 18,15.982L18,2.018Z"
          />
        </g>
        <path
          d="M5.637,10.782C5.637,10.782 3.357,8.645 3.357,8.645C2.881,8.171 2.881,7.403 3.357,6.927C3.835,6.453 4.608,6.453 5.086,6.927L6.346,8.052L9.172,3.506C9.566,2.963 10.327,2.836 10.878,3.228C11.425,3.619 11.551,4.378 11.158,4.923L7.492,10.632C7.273,10.938 6.912,11.139 6.5,11.139C6.178,11.139 5.868,11.012 5.637,10.782Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

IconCheckbox.defaultProps = { className: null, checkedClassName: null, boxClassName: null };

IconCheckbox.propTypes = { className: string, checkedClassName: string, boxClassName: string };

const FieldCheckboxComponent = props => {
  const {
    rootClassName,
    className,
    svgClassName,
    textClassName,
    id,
    label,
    checked,
    useSuccessColor,
    ...rest
  } = props;

  const disabled = rest.disabled;
  const classes = classNames(rootClassName || css.root);

  // This is a workaround for a bug in Firefox & React Final Form.
  // https://github.com/final-form/react-final-form/issues/134
  const handleOnChange = (input, event) => {
    const { onBlur, onChange } = input;
    onChange(event);
    onBlur(event);

    // If onChange has been passed as a props to FieldCheckbox
    if (rest.onChange) {
      rest.onChange(event);
    }
  };

  let successColorVariantMaybe = useSuccessColor
    ? {
        checkedClassName: css.checkedSuccess,
        boxClassName: css.boxSuccess,
      }
    : {};

  return (
    <span className={classes}>
      <Field type="checkbox" {...rest}>
        {props => {
          const input = props.input;
          return (
            <input
              id={id}
              className={css.input}
              {...input}
              onChange={event => handleOnChange(input, event)}
              disabled={disabled}
              checked={checked}
            />
          );
        }}
      </Field>
      <label htmlFor={id} className={css.label}>
        <span className={css.checkboxWrapper}>
          <IconCheckbox className={svgClassName} {...successColorVariantMaybe} />
        </span>
        <span className={classNames(css.text, textClassName || css.textRoot)}>{label}</span>
      </label>
    </span>
  );
};

FieldCheckboxComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  textClassName: null,
  label: null,
};

FieldCheckboxComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  textClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default FieldCheckboxComponent;
