import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './IconArrowTopRight.module.css';

const IconArrowTopRight = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.4 14.2001L0.800003 12.6001L10.275 3.1501H1.85V0.850098H14.15V13.1501H11.85V4.7251L2.4 14.2001Z"
        fill="#E6E6E6"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconArrowTopRight.defaultProps = {
  className: null,
  rootClassName: null,
};

IconArrowTopRight.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconArrowTopRight;
