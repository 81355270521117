import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './IconWarning.module.css';

const IconWarning = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="25" height="22" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 21.125 12.244 0 24.49 21.125H0Zm11.118-6.775h2.253V9.575h-2.253v4.775Zm1.126 3.125c.35 0 .647-.121.889-.363.242-.241.363-.537.363-.887 0-.333-.12-.621-.363-.863a1.21 1.21 0 0 0-.889-.362c-.334 0-.626.12-.876.362a1.16 1.16 0 0 0-.376.863c0 .35.126.646.376.887.25.242.542.363.876.363Z" />
    </svg>
  );
};

const { string } = PropTypes;

IconWarning.defaultProps = {
  className: null,
  rootClassName: null,
};

IconWarning.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconWarning;
