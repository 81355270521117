import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoneyMinimal } from '../../util/currency';
import { propTypes } from '../../util/types';
import { object } from 'prop-types';
import config from '../../config';
import { DutchPrice } from '../../components';

import css from './OrderBreakdown.module.css';

const LineItemBasePriceMaybe = props => {
  const { lineItems, unitType, intl, transaction, firstImage, listing } = props;
  let humanizedCode;
  let selectedUnit;

  // Find correct line-item for given unitType prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
  // These are defined in '../../util/types';
  const unitPurchase = lineItems.find(item => item.code === unitType && !item.reversal);

  const unitOptions = config.custom.listingUnits;
  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  const total = unitPurchase ? formatMoneyMinimal(intl, unitPurchase.lineTotal) : null;
  const isCart = !!transaction.attributes?.protectedData?.cart;
  if (isCart) {
    const itemData = transaction.attributes?.protectedData?.cartListingLineItems[0];
    humanizedCode = itemData?.title;
    selectedUnit = unitOptions.find(u => u.key === itemData?.unit);
  } else {
    humanizedCode = listing?.attributes?.title;
    const unit = listing?.attributes?.publicData?.unit;
    selectedUnit = unitOptions.find(u => u.key === unit);
  }
  const quantityMultiple = quantity && quantity > 1;
  const unitLabel = selectedUnit
    ? intl.formatMessage({
        id: quantityMultiple ? selectedUnit.labelPlural : selectedUnit.labelSingular,
      })
    : '';

  let quantityLabel = '';
  if (quantityMultiple) {
    if (selectedUnit?.key === 'per100Gram') {
      quantityLabel = `${quantity} x ${unitLabel}`;
    } else {
      quantityLabel = `${quantity} ${unitLabel}`;
    }
  } else {
    if (selectedUnit?.key === 'per100Gram') {
      quantityLabel = `1 x ${unitLabel}`;
    } else {
      quantityLabel = `1 ${unitLabel}`;
    }
  }

  return quantity && total ? (
    <div className={css.lineItem}>
      <div className={css.itemImage}>
        <img src={firstImage?.attributes.variants['listing-card'].url} />
      </div>
      <div className={css.itemInfo}>
        <p>
          <strong>{humanizedCode}</strong>
        </p>
        <p>{quantityLabel}</p>
      </div>
      <div className={css.itemValue}>
        <DutchPrice price={total} showCurrency />
      </div>
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  unitType: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
  transaction: propTypes.transaction.isRequired,
  firstImage: object,
};

export default LineItemBasePriceMaybe;
