import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './IconBike.module.css';

const IconBike = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="18" height="14" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.9 1.313C9.9.588 10.505 0 11.25 0c.745 0 1.35.588 1.35 1.313 0 .724-.605 1.312-1.35 1.312-.745 0-1.35-.588-1.35-1.313Zm3.6 3.037c.498 0 .9.416.9.875 0 .509-.402.875-.9.875h-1.8c-.205 0-.402-.043-.563-.191l-1.144-.867-1.696 1.326 1.204.78c.25.161.399.434.399.727v3.5a.887.887 0 0 1-.9.875.887.887 0 0 1-.9-.875V8.343L6.398 7.295c-.922-.593-.984-1.856-.12-2.504l2.337-1.759a1.383 1.383 0 0 1 1.662.009l1.738 1.31H13.5ZM7.2 10.5c0 1.933-1.612 3.5-3.6 3.5S0 12.433 0 10.5 1.612 7 3.6 7s3.6 1.567 3.6 3.5ZM3.6 8.531c-1.118 0-2.025.88-2.025 1.969 0 1.088.907 1.969 2.025 1.969 1.12 0 2.025-.88 2.025-1.969 0-1.088-.906-1.969-2.025-1.969ZM18 10.5c0 1.933-1.612 3.5-3.6 3.5s-3.6-1.567-3.6-3.5S12.412 7 14.4 7 18 8.567 18 10.5Zm-3.6-1.969c-1.12 0-2.025.88-2.025 1.969 0 1.088.906 1.969 2.025 1.969 1.12 0 2.025-.88 2.025-1.969 0-1.088-.906-1.969-2.025-1.969Z" />
    </svg>
  );
};

const { string } = PropTypes;

IconBike.defaultProps = {
  className: null,
  rootClassName: null,
};

IconBike.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconBike;
