import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';

import config from '../../../config';
import { FormattedMessage, intlShape, injectIntl } from '../../../util/reactIntl';
import { isMainSearchTypeKeywords } from '../../../util/search';

import { Form, LocationAutocompleteInput, PrimaryButton, IconInfo } from '../../../components';

import IconSearchDesktop from './IconSearchDesktop';
import css from './TopbarSearchForm.module.css';

const identity = v => v;

const KeywordSearchField = props => {
  const { keywordSearchWrapperClasses, iconClass, intl, isMobile, inputRef } = props;
  const id = isMobile ? 'keyword-search-mobile' : 'keyword-search';
  const label = intl.formatMessage({
    id: 'TopbarSearchForm.keywordsLabel',
  });
  return (
    <div className={keywordSearchWrapperClasses}>
      <Field
        name="keywords"
        render={({ input, meta }) => {
          return (
            <>
              {isMobile && <label htmlFor={id}>{label}</label>}
              <input
                className={isMobile ? css.mobileInput : css.desktopInput}
                {...input}
                id={id}
                ref={inputRef}
                type="text"
                placeholder={intl.formatMessage({
                  id: 'TopbarSearchForm.placeholder',
                })}
                autoComplete="off"
              />
            </>
          );
        }}
      />
    </div>
  );
};

const LocationSearchField = props => {
  const { desktopInputRootClass, intl, isMobile, inputRef, onLocationChange } = props;
  const id = isMobile ? 'location-mobile' : 'location';
  const label = intl.formatMessage({
    id: 'TopbarSearchForm.locationLabel',
  });
  return (
    <Field
      id={id}
      name="location"
      format={identity}
      render={({ input, meta }) => {
        const { onChange, ...restInput } = input;

        // Merge the standard onChange function with custom behaviur. A better solution would
        // be to use the FormSpy component from Final Form and pass this.onChange to the
        // onChange prop but that breaks due to insufficient subscription handling.
        // See: https://github.com/final-form/react-final-form/issues/159
        const searchOnChange = value => {
          onChange(value);
          onLocationChange(value);
        };

        return (
          <div className={css.locationSearchWrapper}>
            {isMobile && <label htmlFor={id}>{label}</label>}
            <LocationAutocompleteInput
              className={isMobile ? css.mobileInputRoot : desktopInputRootClass}
              iconClassName={css.desktopLocationIcon}
              inputClassName={isMobile ? css.mobileInput : css.desktopInput}
              predictionsClassName={isMobile ? css.mobilePredictions : css.desktopPredictions}
              predictionsAttributionClassName={isMobile ? css.mobilePredictionsAttribution : null}
              placeholder={intl.formatMessage({ id: 'TopbarSearchForm.locationPlaceholder' })}
              closeOnBlur={!isMobile}
              inputRef={inputRef}
              input={{ ...restInput, onChange: searchOnChange }}
              meta={meta}
            />
          </div>
        );
      }}
    />
  );
};

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    // onChange is used for location search
    this.onChange = this.onChange.bind(this);
    // onSubmit is used for keywords search
    this.onSubmit = this.onSubmit.bind(this);

    // Callback ref
    this.searchInput = null;
    this.setSearchInputRef = element => {
      this.setSearchInput = element;
    };
  }

  onChange(form) {
    return location => {
      const { appConfig, onSubmit } = this.props;
      const values = form.getState().values;
      const { keywords } = values;
      if (location.selectedPlace) {
        // Note that we use `onSubmit` instead of the conventional
        // `handleSubmit` prop for submitting. We want to autosubmit
        // when a place is selected, and don't require any extra
        // validations for the form.
        onSubmit({ location, keywords });
        // blur search input to hide software keyboard
        this.searchInput?.blur();
      }
    };
  }

  onSubmit(values) {
    const { appConfig, onSubmit } = this.props;
    const { keywords, location } = values;
    onSubmit({ keywords, location });
    // blur search input to hide software keyboard
    this.searchInput?.blur();
  }

  render() {
    const { onSubmit, appConfig, ...restOfProps } = this.props;
    const isKeywordsSearch = isMainSearchTypeKeywords(appConfig);
    const submit = isKeywordsSearch ? this.onSubmit : onSubmit;
    return (
      <FinalForm
        {...restOfProps}
        onSubmit={submit}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            desktopInputRoot,
            intl,
            isMobile,
            handleSubmit,
            locationInputClass,
            form,
          } = formRenderProps;
          const classes = classNames(rootClassName, className);
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;

          // Location search: allow form submit only when the place has changed
          const preventFormSubmit = e => e.preventDefault();
          const submitFormFn = isKeywordsSearch ? handleSubmit : preventFormSubmit;

          const keywordSearchWrapperClasses = classNames(
            css.keywordSearchWrapper,
            isMobile ? css.mobileInputRoot : desktopInputRootClass
          );
          const locationInputRootClass = classNames(desktopInputRootClass, locationInputClass);

          return (
            <Form className={classes} onSubmit={submitFormFn} enforcePagePreloadFor="SearchPage">
              <div className={css.searchFields}>
                <KeywordSearchField
                  keywordSearchWrapperClasses={keywordSearchWrapperClasses}
                  iconClass={classNames(isMobile ? css.mobileIcon : css.desktopIcon || css.icon)}
                  intl={intl}
                  isMobile={isMobile}
                  inputRef={this.setSearchInputRef}
                />
                <LocationSearchField
                  desktopInputRootClass={locationInputRootClass}
                  iconClass={classNames(isMobile ? css.mobileIcon : css.desktopIcon || css.icon)}
                  intl={intl}
                  isMobile={isMobile}
                  inputRef={this.setSearchInputRef}
                  onLocationChange={this.onChange(form)}
                />
                {isMobile ? (
                  <>
                    <div className={css.info}>
                      <div className={css.infoIcon}>
                        <IconInfo />
                      </div>
                      <div className={css.infoText}>
                        <FormattedMessage id="TopbarSearchForm.info" />
                      </div>
                    </div>
                    <PrimaryButton className={css.searchMobileButton} type="submit">
                      <FormattedMessage id="TopbarSearchForm.buttonTitle" />
                    </PrimaryButton>
                  </>
                ) : (
                  <button className={css.searchSubmit}>
                    <div
                      className={classNames(
                        isMobile ? css.mobileIcon : css.desktopIcon || css.icon
                      )}
                    >
                      <IconSearchDesktop />
                    </div>
                  </button>
                )}
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
  appConfig: config,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,
  appConfig: object,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
