import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './IconShop.module.css';

const IconShop = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="16" height="14" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.166 6.242v6.591c0 .311-.12.584-.358.817-.239.233-.518.35-.836.35H2.012c-.32 0-.598-.117-.837-.35a1.107 1.107 0 0 1-.358-.817V6.242a2.196 2.196 0 0 1-.736-1.148 2.38 2.38 0 0 1 .04-1.36l.855-2.626c.106-.35.292-.622.557-.816C1.8.097 2.103 0 2.45 0h11.006c.371 0 .696.1.975.301.278.201.47.47.577.807l.876 2.625c.145.454.155.908.03 1.361a2.255 2.255 0 0 1-.747 1.148Zm-5.373-.603c.384 0 .71-.123.975-.37a.96.96 0 0 0 .318-.894l-.497-3.208h-1.99v3.208c0 .337.112.632.338.885.225.253.51.379.856.379Zm-3.722 0c.372 0 .687-.123.945-.37a1.19 1.19 0 0 0 .388-.894V1.167h-1.99l-.497 3.208c-.053.337.04.632.278.885.24.253.531.379.876.379Zm-3.622 0c.318 0 .594-.107.826-.32.232-.215.368-.477.408-.788L4.2 1.167H2.21l-.915 2.839c-.133.401-.08.774.159 1.118.239.343.57.515.995.515Zm11.085 0c.425 0 .76-.169 1.005-.506.246-.337.302-.713.17-1.127l-.916-2.84h-1.99l.517 3.365c.04.31.176.573.408.787.232.214.501.32.806.32ZM2.011 12.833h11.961V6.786c.013.013-.03.02-.13.02h-.308c-.332 0-.647-.069-.945-.205a3.165 3.165 0 0 1-.886-.632 2.24 2.24 0 0 1-.796.613c-.318.149-.67.224-1.055.224-.398 0-.74-.056-1.025-.166a2.464 2.464 0 0 1-.826-.554 1.918 1.918 0 0 1-.756.535c-.305.123-.65.185-1.035.185-.411 0-.776-.072-1.094-.214a2.232 2.232 0 0 1-.816-.623c-.319.273-.63.48-.936.623-.305.142-.61.214-.915.214H2.18a.463.463 0 0 1-.169-.02v6.047Zm11.961 0H2.012h11.96Z" />
    </svg>
  );
};

const { string } = PropTypes;

IconShop.defaultProps = {
  className: null,
  rootClassName: null,
};

IconShop.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconShop;
