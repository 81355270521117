import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { string, func, number, shape, bool } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoneyMinimal } from '../../util/currency';
import { ensureListing, ensureUser, displayListingUnit } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { withViewport } from '../../util/contextHelpers';
import config from '../../config';
import {
  AspectRatioWrapper,
  Avatar,
  DutchPrice,
  NamedLink,
  ResponsiveImage,
} from '../../components';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoneyMinimal(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const truncateTitle = (title, vw) => {
  if (vw <= 375) {
    return title.length < 18 ? title : title.substring(0, 14) + '...';
  }
  if (vw <= 393) {
    return title.length < 19 ? title : title.substring(0, 15) + '...';
  }
  if (vw <= 414) {
    return title.length < 20 ? title : title.substring(0, 16) + '...';
  }
  if (vw <= 550) {
    return title.length < 23 ? title : title.substring(0, 19) + '...';
  }
  //
  return title;
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    viewport: { width },
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const { formattedPrice, priceTitle } = priceData(price, intl);
  const unit = displayListingUnit(currentListing, intl);

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  return (
    <>
      <div className={css.mobile}>
        <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
          <div>
            <div>
              <AspectRatioWrapper
                className={css.aspectRatioWrapper}
                width={aspectWidth}
                height={aspectHeight}
                {...setActivePropsMaybe}
              >
                <LazyImage
                  rootClassName={css.rootForImage}
                  alt={title}
                  image={firstImage}
                  variants={variants}
                  sizes={renderSizes}
                />
                <Avatar className={css.authorImageMobile} user={author} size="small" />
              </AspectRatioWrapper>
            </div>
            <div style={{ margin: 8 }}>
              <div className={css.title}>{truncateTitle(title, width)}</div>
              <div className={css.unit}>{unit}</div>
              <div className={css.price}>
                <div className={css.priceValue} title={priceTitle}>
                  {+formattedPrice ? (
                    <DutchPrice price={formattedPrice} />
                  ) : (
                    <span className={css.gratisPriceValue}>Gratis</span>
                  )}
                </div>
              </div>
              <div className={css.mobileProductInfo}>
                <div>Meer info</div>
                <div>
                  <NamedLink
                    name="ListingPage"
                    params={{ id, slug }}
                    className={classNames(css.heroButtonPrimaryMobile)}
                  >
                    +
                  </NamedLink>
                </div>
              </div>
            </div>
          </div>
        </NamedLink>
      </div>
      <div className={css.desktop}>
        <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
          {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <Avatar className={css.authorImage} user={author} size="small" />
              <span className={css.authorName}>{authorName}</span>
            </div>
          ) : null}
          <AspectRatioWrapper
            className={css.aspectRatioWrapper}
            width={aspectWidth}
            height={aspectHeight}
            {...setActivePropsMaybe}
          >
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={variants}
              sizes={renderSizes}
            />
          </AspectRatioWrapper>
          <div className={css.info}>
            <div className={css.mainInfo}>
              <div className={css.title}>
                {richText(title, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </div>
              <div className={css.unit}>{unit}</div>
            </div>
            <div className={css.price}>
              <div className={css.priceValue} title={priceTitle}>
                {+formattedPrice ? (
                  <DutchPrice price={formattedPrice} />
                ) : (
                  <span className={css.gratisPriceValue}>Gratis</span>
                )}
              </div>
              <NamedLink
                name="ListingPage"
                params={{ id, slug }}
                className={classNames(css.heroButtonPrimary)}
              >
                Bekijk product
              </NamedLink>
            </div>
          </div>
        </NamedLink>
      </div>
    </>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,

  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

export default compose(
  withViewport,
  injectIntl
)(ListingCardComponent);
