import pick from 'lodash/pick';

import config from '../../config';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { updateCartSetInitial } from '../../ducks/cart.duck';

const RESULT_PAGE_SIZE = 100;

// ================ Action types ================ //

export const CART_LISTINGS_REQUEST = 'app/SearchPage/CART_LISTINGS_REQUEST';
export const CART_LISTINGS_SUCCESS = 'app/SearchPage/CART_LISTINGS_SUCCESS';
export const CART_LISTINGS_ERROR = 'app/SearchPage/CART_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  cartInProgress: false,
  cartListingsError: null,
  currentPageResultIds: [],
};

const resultIds = data => data.data.map(l => l.id);

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CART_LISTINGS_REQUEST:
      return {
        ...state,
        cartInProgress: true,
        cartListingsError: null,
      };
    case CART_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        cartInProgress: false,
      };
    case CART_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, cartInProgress: false, cartListingsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const cartListingsRequest = () => ({
  type: CART_LISTINGS_REQUEST,
});

export const cartListingsSuccess = response => ({
  type: CART_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const cartListingsError = e => ({
  type: CART_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const userCart = state => {
  const { user } = state;
  const { currentUser } = user;
  return (currentUser && currentUser.attributes.profile.publicData.cart) || [];
};

const currentCartListingIds = cart => {
  return cart.map(i => i.id);
};

export const loadData = () => (dispatch, getState, sdk) => {
  dispatch(updateCartSetInitial());
  const cart = userCart(getState());
  if (!cart) {
    return Promise.resolve(true);
  }
  dispatch(cartListingsRequest());
  const page = 1;
  const ids = currentCartListingIds(cart);
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;
  const queryParams = {
    ids,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images', 'currentStock'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  };
  return sdk.listings
    .query(queryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(cartListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(cartListingsError(storableError(e)));
      throw e;
    });
};
