import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconBell.module.css';

const IconBell = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="28"
      height="34"
      viewBox="0 0 28 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.583 18.917H15.417V10.375H12.583V18.917ZM14 23.583C14.4167 23.583 14.7707 23.4373 15.062 23.146C15.354 22.854 15.5 22.4997 15.5 22.083C15.5 21.639 15.354 21.271 15.062 20.979C14.7707 20.6877 14.4167 20.542 14 20.542C13.5833 20.542 13.2293 20.6877 12.938 20.979C12.646 21.271 12.5 21.639 12.5 22.083C12.5 22.4997 12.646 22.854 12.938 23.146C13.2293 23.4373 13.5833 23.583 14 23.583ZM0.208008 28.917V25.75H3.75001V13.667C3.75001 11.2777 4.45134 9.13867 5.85401 7.25C7.25667 5.36133 9.11101 4.167 11.417 3.667V2.5C11.417 1.80533 11.667 1.215 12.167 0.729C12.667 0.243 13.278 0 14 0C14.722 0 15.333 0.243 15.833 0.729C16.333 1.215 16.583 1.80533 16.583 2.5V3.667C18.889 4.167 20.7503 5.35433 22.167 7.229C23.5837 9.10433 24.292 11.2503 24.292 13.667V25.75H27.833V28.917H0.208008ZM14 33.958C13.0833 33.958 12.2847 33.618 11.604 32.938C10.9233 32.2573 10.583 31.4587 10.583 30.542H17.417C17.417 31.486 17.0837 32.2913 16.417 32.958C15.7503 33.6247 14.9447 33.958 14 33.958Z" />
    </svg>
  );
};

const { string } = PropTypes;

IconBell.defaultProps = {
  className: null,
  rootClassName: null,
};

IconBell.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconBell;
