import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './IconPlant.module.css';

const IconPlant = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="14" height="13" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.75 1.715H0c0 3.364 2.743 6.087 6.125 6.087v3.478c0 .503.394.894.85.894.457 0 .9-.391.9-.87V7.826c0-3.361-2.743-6.111-6.125-6.111ZM12.25 0a6.129 6.129 0 0 0-5.354 3.133A6.915 6.915 0 0 1 8.51 6.054C11.594 5.736 14 3.15 14 0h-1.75Z" />
    </svg>
  );
};

const { string } = PropTypes;

IconPlant.defaultProps = {
  className: null,
  rootClassName: null,
};

IconPlant.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPlant;
