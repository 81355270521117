import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconBinoculars.module.css';

const IconBinoculars = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="24" height="21" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5.75a.747.747 0 0 0-.75-.75h-3a.747.747 0 0 0-.75.75V3h4.502L19.5.75ZM2.994 6.005C2.875 10.402.164 11.358 0 17.438V19.5c0 .825.675 1.5 1.5 1.5h6c.825 0 1.5-.675 1.5-1.5v-15H4.494c-.826 0-1.478.68-1.5 1.505Zm18.01 0c-.023-.825-.674-1.505-1.5-1.505H15v15c0 .825.675 1.5 1.5 1.5h6c.825 0 1.5-.675 1.5-1.5v-2.063c-.164-6.08-2.873-7.035-2.995-11.432ZM10.5 12h3V4.5h-3V12ZM8.25 0h-3a.747.747 0 0 0-.75.75L4.498 3H9V.75A.747.747 0 0 0 8.25 0Z" />
    </svg>
  );
};

const { string } = PropTypes;

IconBinoculars.defaultProps = {
  className: null,
  rootClassName: null,
};

IconBinoculars.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconBinoculars;
