import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.module.css';

const SearchIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootSearchIcon, className);

  return (
    <svg className={classes} width="35" height="35" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#a)" transform="translate(-277 -18)" fill="#FEFEFE" fillRule="nonzero">
        <path d="m307.292 49.75-10.667-10.625c-.833.722-1.84 1.27-3.021 1.645-1.18.375-2.326.563-3.437.563-2.861 0-5.271-.979-7.23-2.937-1.958-1.959-2.937-4.369-2.937-7.23 0-2.833.979-5.236 2.937-7.208 1.959-1.972 4.355-2.958 7.188-2.958 2.833 0 5.243.979 7.229 2.937 1.986 1.959 2.979 4.368 2.979 7.229 0 1.167-.194 2.313-.583 3.438a9.324 9.324 0 0 1-1.667 2.979l10.709 10.625-1.5 1.542Zm-17.125-10.542c2.277 0 4.187-.77 5.729-2.312 1.541-1.542 2.312-3.452 2.312-5.73 0-2.277-.77-4.187-2.312-5.729-1.542-1.541-3.452-2.312-5.729-2.312-2.306 0-4.223.77-5.75 2.312-1.528 1.542-2.292 3.452-2.292 5.729 0 2.278.764 4.188 2.292 5.73 1.527 1.541 3.444 2.312 5.75 2.312Z" />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

SearchIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

SearchIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default SearchIcon;
