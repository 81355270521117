import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoneyMinimal } from '../../util/currency';
import { propTypes, LINE_ITEM_PROVIDER_COMMISSION } from '../../util/types';
import { DutchPrice } from '../../components';

import css from './OrderBreakdown.module.css';

const LineItemProviderCommissionRefundMaybe = props => {
  const { lineItems, isProvider, intl } = props;

  const refund = lineItems.find(
    item => item.code === LINE_ITEM_PROVIDER_COMMISSION && item.reversal
  );

  const refundAmount = refund ? formatMoneyMinimal(intl, refund.lineTotal) : null;

  return isProvider && refund ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.refundProviderFee" />
      </span>
      <span className={css.itemValue}>
        <DutchPrice price={refundAmount} showCurrency />
      </span>
    </div>
  ) : null;
};

LineItemProviderCommissionRefundMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemProviderCommissionRefundMaybe;
